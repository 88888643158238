import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <>
            <div className="img-gl overlay">
                <div className="content-gl">
                    <div className="text-gl">
                        <h1>404</h1>
                        <p className="m-3">Página no encontrada</p>
                        <Link to="/" className="btn btn-border-white m-3">Volver al inicio</Link>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default NotFound;