import React, { useEffect } from 'react';

const CookieScript = () => {
  useEffect(() => {
    // Carga el script cuando el componente se monta
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.src = '//cdn.cookie-script.com/s/f6efa3acb78179a4bab4a3ca9c1eec3b.js';
    document.head.appendChild(script);

    // Limpia el script cuando el componente se desmonta
    return () => {
      document.head.removeChild(script);
    };
  }, []); // El segundo parámetro vacío asegura que este efecto solo se ejecute una vez al montar el componente

  return <></>; // Un componente de función debe devolver algo, incluso si es nulo
};

export default CookieScript;