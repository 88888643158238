import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './routes/home'
import Blog from './routes/blog'
import Articulo from './routes/articulo'
import AvisoLegal from './routes/aviso-legal'
import PoliticaPrivacidad from './routes/politica-privacidad';
import PoliticaCookies from './routes/politica-cookies';
import NotFound from './routes/404';
import Transparencia from './routes/transparencia';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path='/blog/:slug' element={<Articulo/>} />
          <Route path='/aviso-legal' element={<AvisoLegal/>} />
          <Route path='/politica-privacidad' element={<PoliticaPrivacidad/>} />
          <Route path='/politica-cookies' element={<PoliticaCookies/>} />
          <Route path='/transparencia' element={<Transparencia/>} />
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
