import React from "react";
import NavBar from './../components/navbar'
import Footer from "../components/footer";
import Img1 from "./../images/slide-home-grupo-lae-empresas.jpg"
import { Link } from "react-router-dom";

const PoliticaPrivacidad = () => {
    return ( 
        <>
            <NavBar />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto">
                        <h1 className="text-white mb-1 text-white">POLÍTICA DE PRIVACIDAD</h1>
                        <span className='linea-header'></span>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container politicas text-left">
                    <h5>PROTECCIÓN DE DATOS LAE FORMACIÓN PROGRAMADA, S.L.</h5>
                    <p>La empresa garantiza a los usuarios de la página web que cumple con la Ley Orgánica 3/2018, de 5 de diciembre, de <strong>Protección de Datos Personales y Garantía de los Derechos Digitales</strong>, y que ha establecido las medidas de seguridad exigidas por las leyes y reglamentos destinados a preservar el secreto, confidencialidad e integridad en el tratamiento de sus datos personales.</p>
                    <p>Dichos datos personales serán utilizados en la forma y con las limitaciones y derechos que concede la protección de datos de carácter personal.</p>
                    <p>La recogida y tratamiento de los datos personales solicitados tiene como:</p>
                    <ul>
                        <li><strong>Finalidad</strong>: Resolver las dudas que nos plantea a través del formulario. Incluir su candidatura en futuros procesos de selección de la empresa. Según proceda, además, facilitarle la información que nos solicita a través de los formularios.</li>
                        <li><strong>Legitimación</strong>: La base legal para el tratamiento de sus datos personales es el consentimiento expreso que presta al aceptar esta Política de Privacidad antes de enviarnos/ facilitarnos sus datos y el interés legítimo del responsable del tratamiento en mantenerle informado sobre los servicios de la empresa además del cumplimiento de las obligaciones legales.</li>
                        <li><strong>Destinatarios</strong>: no se comunicará sus datos a ningún tercero, salvo que se le informe de ello expresamente o exista obligación legal. LAE Formación Programada, S.L. se compromete a guardar la máxima reserva, secreto y confidencialidad sobre la información de los datos personales que tiene a su disposición.</li>
                        <li><strong>Duración</strong>: Sus datos, serán conservados el tiempo mínimo necesario para satisfacer la finalidad para la cual los facilitó, así como para atender las responsabilidades que se pudieran derivar de los datos suministrados y de cualquier otra exigencia legal.</li>
                        <li><strong>Derechos</strong>: El interesado es titular de los derechos de acceso, rectificación, supresión, limitación y oposición al tratamiento de sus datos personales, así como el derecho a la portabilidad de los mismos. Asimismo, en cualquier momento el interesado puede retirar el consentimiento prestado sin que ello afecte a la licitud del tratamiento, así como la posibilidad de acudir ante la autoridad de control competente (AEPD). El ejercicio de derechos podrá realizarse también solicitándolos a nuestro Delegado de Protección de Datos: <Link to="mailto:dpo@grupolae.net" target="_blank">dpo@grupolae.net.</Link></li>
                    </ul>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 225px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
     );
}
 
export default PoliticaPrivacidad;